var sliders = [];

$(document).ready(function ($) {

	// Sudo slider
	if ($.fn.sudoSlider) {

		// News
		$('.news-slider').each(function () {
			var panels = $(this).find('.panel'),
				panelNum = panels.length;

			if (panelNum > 1) {
				newsSlider = $(this).find('.slider').sudoSlider({
					vertical: false,
					auto: true,
					pause: '5000',
					autowidth: false,
					continuous: true,
					prevNext: true,
					numeric: true,
					slideCount: 1,
					speed: 250,
					responsive: true,
					controlsAttr: 'class="slider-nav"',
					prevHtml: '<span href="#" class="prev"></span>',
					nextHtml: '<span href="#" class="next"></span>',
					beforeAnimation: function (t, slider) {
						panels.removeClass('panel-current');
						panels.eq(t-1).addClass('panel-current');
					}
				});
			}
		});

		// Selections
		$('.selection-slider').each(function () {
			var panels = $(this).find('.panel'),
				panelNum = panels.length;

			if (panelNum > 1) {
				selectionSlider = $(this).find('.slider').sudoSlider({
					vertical: false,
					auto: false,
					autowidth: false,
					continuous: false,
					prevNext: true,
					numeric: false,
					slideCount: 1,
					speed: 250,
					responsive: true,
					controlsAttr: 'class="slider-nav"',
					prevHtml: '<a href="#" class="prev"></a>',
					nextHtml: '<a href="#" class="next"></a>',
					beforeAnimation: function (t, slider) {
						panels.removeClass('panel-current');
						panels.eq(t-1).addClass('panel-current');
					}
				});
			}
		});

		// Playlists
		$('.playlist-slider').each(function () {
			var panels = $(this).find('.panel'),
				panelNum = panels.length;

			if (panelNum > 1) {
				playlistSlider = $(this).find('.slider').sudoSlider({
					vertical: false,
					auto: false,
					autowidth: false,
					continuous: false,
					prevNext: true,
					numeric: false,
					slideCount: 1,
					speed: 250,
					responsive: true,
					controlsAttr: 'class="slider-nav"',
					prevHtml: '<a href="#" class="prev"></a>',
					nextHtml: '<a href="#" class="next"></a>',
					initCallback: function () {
						panels.first().addClass('panel-current');
					},
					beforeAnimation: function (t, slider) {
						panels.removeClass('panel-current');
						panels.eq(t-1).addClass('panel-current');
					}
				});
			}
		});
	}

});
